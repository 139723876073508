import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import * as styles from './SecondaryNav.module.scss';

function SecondaryNav(props) {
  const { nav } = props;
  const {
    menuItems: {
      nodes: menuItems,
    },
  } = nav;

  const renderLink = (link) => {
    if (link.target === '_blank') {
      return <a href={link.url} target="_blank" rel="noreferrer">{link.label}</a>;
    }
    return <Link to={link.uri}>{link.label}</Link>;
  };

  const renderNav = () => {
    if (!menuItems.length) return null;

    return (
      <ul className={styles.navList}>
        {menuItems.map((menuItem) => (
          <Fragment key={menuItem.id}>
            <li className={menuItem.cssClasses.join(' ')}>{renderLink(menuItem)}</li>
            {menuItem.cssClasses.find((el) => el === 'line-break-after') ? <br /> : ''}
          </Fragment>
        ))}
      </ul>
    );
  };

  return (
    <div className={styles.secondaryNavWrap}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {renderNav()}
          </div>
        </div>
      </div>
    </div>
  );
}

SecondaryNav.propTypes = {
  nav: PropTypes.shape({
    menuItems: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        url: PropTypes.string.isRequired,
        target: PropTypes.string,
        cssClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired,
};
export default SecondaryNav;
