import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import * as styles from './CategoriesNav.module.scss';
import { getCategoryArchiveUrl } from '../../utilities/permalinks';

function CategoriesNav(props) {
  const { categories } = props;

  const renderContent = () => {
    if (categories.length) {
      return (
        <div className={styles.categoriesNavWrap}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <ul className={styles.categoriesNav}>
                  {categories.map((category) => (
                    <li key={category.id}>
                      <Link to={getCategoryArchiveUrl(category.slug)}>
                        {category.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  return <>{renderContent()}</>;
}
CategoriesNav.defaultProps = {
  categories: [],
};
CategoriesNav.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
};
export default CategoriesNav;
