import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import * as styles from './TypeNav.module.scss';
import { getTypeArchiveUrl } from '../../utilities/permalinks';

const TypeNav = (props) => {
  const { types } = props;

  const renderNav = () => {
    if (!types.length) return null;

    return (
      <div className={styles.typeNavWrap}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ul className={styles.typeNavList}>
                {types.map((type) => (
                  <li key={type.id}>
                    <Link to={getTypeArchiveUrl(type.slug)}>{`- ${type.name}`}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return renderNav();
};
TypeNav.propTypes = {
  types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};
export default TypeNav;
