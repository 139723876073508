import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './BackgroundMedia.module.scss';
import BackgroundImage from './BackgroundImage';
import BackgroundVideo from './BackgroundVideo';

function BackgroundMedia(props) {
  const {
    background: { backgroundMedia },
  } = props;
  if (!backgroundMedia) return null;

  const background = backgroundMedia.find(() => true);
  let selectedMedia = '';

  switch (background.fieldGroupName) {
    case 'Template_Home_Background_BackgroundMedia_BackgroundVideo':
      selectedMedia = <BackgroundVideo videos={background} />;
      break;
    case 'Template_Home_Background_BackgroundMedia_BackgroundImage':
      selectedMedia = <BackgroundImage images={background} />;
      break;
    default:
      selectedMedia = '';
      break;
  }

  return <div className={styles.backgroundMediaWrap}>{selectedMedia}</div>;
}

BackgroundMedia.defaultProps = {
  background: {
    backgroundMedia: null,
  },
};
BackgroundMedia.propTypes = {
  background: PropTypes.shape({
    backgroundMedia: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default BackgroundMedia;
