import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import { Player, ControlBar } from 'video-react';
import * as styles from './BackgroundVideo.module.scss';

function BackgroundVideo(props) {
  const { videos } = props;
  const [isDesktop, setIsDesktop] = useState(false);
  const matches = useMediaQuery('only screen and (min-width: 768px)');

  /* Remove `matches` from dependency if we want to keep initial media after resize */
  useEffect(() => {
    setIsDesktop(matches);
  }, [isDesktop, matches]);

  const renderVideo = () => {
    const attributes = {
      playsInline: true,
      src: videos.mobileVideoUrl,
      className: styles.backgroundVideoPlayer,
      autoPlay: true,
      muted: true,
      loop: true,
    };
    if (isDesktop && videos.desktopVideoUrl) {
      attributes.src = videos.desktopVideoUrl;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Player {...(attributes)}><ControlBar disableDefaultControls /></Player>;
  };

  return <div className={styles.backgroundVideoWrap}>{renderVideo()}</div>;
}

BackgroundVideo.defaultProps = {
  videos: {
    mobileVideoUrl: null,
    desktopVideoUrl: null,
  },
};
BackgroundVideo.propTypes = {
  videos: PropTypes.shape({
    mobileVideoUrl: PropTypes.string.isRequired,
    desktopVideoUrl: PropTypes.string,
  }),
};
export default BackgroundVideo;
