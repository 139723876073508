import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';

import BackgroundMedia from '../components/BackgroundMedia/BackgroundMedia';
import HeaderSpacer from '../components/Helpers/HeaderSpacer';
import CategoriesNav from '../components/CategoriesNav/CategoriesNav';
import TypeNav from '../components/TypeNav/TypeNav';
import SecondaryNav from '../components/SecondaryNav/SecondaryNav';
import usePageStyles from '../hooks/usePageStyles';

function Home(props) {
  const {
    data: {
      wpPage,
      types: { nodes: types },
      categories: { nodes: categories },
      secondaryNav,
    },
  } = props;
  const {
    template: { background },
    pageStyles,
  } = wpPage;

  usePageStyles({
    backgroundColor: pageStyles.backgroundColor,
    textColor: '#fff',
  });

  const renderNav = () => {
    if (!secondaryNav) return null;

    return (
      <div className="home-navs-wrapper">
        <SecondaryNav nav={secondaryNav} />
      </div>
    );
  };
  return (
    <section className="full-height d-flex flex-column flex-grow-1">
      <Seo post={wpPage} />
      <BackgroundMedia background={background} />
      <HeaderSpacer />
      <div className="container-fluid home-info-text">
        <div className="row">
          <div className="col-12">
            <p>SITE, FORM, LANGUAGE, IDENTITY</p>
          </div>
        </div>
      </div>
      <CategoriesNav categories={categories} />
      <TypeNav types={types} />
      {renderNav()}
    </section>
  );
}
export default Home;

export const query = graphql`
  query homeQuery($pageId: String) {
    wpPage(id: { eq: $pageId }) {
      seo {
        ...YoastPageSEOContent
      }
      template {
        ... on WpTemplate_Home {
          templateName
          background {
            ...WpTemplate_Home_BackgroundFragment
          }
        }
      }
      pageStyles {
        ...WpPage_PagestylesFragment
      }
    }
    categories: allWpJournalCategory {
      nodes {
        id
        name
        slug
      }
    }
    types: allWpType(filter: { slug: { in: ["archive"] } }) {
      nodes {
        id
        slug
        name
      }
    }
    secondaryNav: wpMenu(locations: { eq: SECONDARY_NAV }) {
      menuItems {
        nodes {
          id
          label
          url
          uri
          target
          cssClasses
        }
      }
    }
  }
`;

Home.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.shape({
      template: PropTypes.shape({
        background: PropTypes.shape({}),
      }),
      pageStyles: PropTypes.shape({
        backgroundColor: PropTypes.string.isRequired,
      }),
      seo: PropTypes.shape({}).isRequired,
    }).isRequired,
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    types: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    secondaryNav: PropTypes.shape({}),
  }).isRequired,
};
