import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './BackgroundImage.module.scss';

function BackgroundImage(props) {
  const { images } = props;
  const [isDesktop, setIsDesktop] = useState(false);
  const matches = useMediaQuery('only screen and (min-width: 992px)');

  /* Remove `matches` from dependency if we want to keep initial media after resize */
  useEffect(() => {
    setIsDesktop(matches);
  }, [isDesktop, matches]);

  const renderImage = () => {
    const selectedImage = isDesktop && images.desktopImage
      ? images.desktopImage : images.mobileImage;

    // TODO - check if localFile is missing when image is deleted and DB entry is left
    if (selectedImage.localFile) {
      return (
        <GatsbyImage
          className={styles.backgroundImage}
          alt={selectedImage.altText}
          image={getImage(selectedImage.localFile)}
        />
      );
    }

    return null;
  };

  return <div className={styles.backgroundImageWrap}>{renderImage()}</div>;
}

BackgroundImage.defaultProps = {
  images: {
    mobileImage: null,
    desktopImage: null,
  },
};
BackgroundImage.propTypes = {
  images: PropTypes.shape({
    mobileImage: PropTypes.shape({
      altText: PropTypes.string.isRequired,
      localFile: PropTypes.shape({}).isRequired,
    }).isRequired,
    desktopImage: PropTypes.shape({
      altText: PropTypes.string.isRequired,
      localFile: PropTypes.shape({}).isRequired,
    }),
  }),
};
export default BackgroundImage;
